import Button from "../components/button"
import Layout from "../components/layout"
import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"

// import logo from '../../content/assets/images/someLogo.jpg';

class IndexPage extends React.Component {
  render() {
    const siteTitle = "webmkd"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            `blog`,
            `gatsby`,
            `javascript`,
            `react`,
            `mike`,
            `dinh`,
            `mike dinh`,
            `mikedinh`,
            `mqd`,
            `mkd`,
          ]}
        />
        <div
          style={{
            width: `fit-content`,
            height: `auto`,
          }}
        >
          {/* <img src={logo} height="auto" width="100%" /> */}
          <Link to="/blog/">
            <Button marginTop="35px">Go to Blog</Button>
          </Link>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
